// Todo: This file needs to be removed and all styling needs to be placed in correct files.

.catalog-category-view {
    .page-title {
        color: $brand__primary__color;
        border-bottom: 1px solid $border-color__base;
        text-transform: uppercase;
        font-size: 35px;
        padding-bottom: 15px;
        margin-bottom: 30px;
    }
}

.actions-wrapper {
    display: flex;
    
    .qty-select {
        margin-right: 10px;
        
        > input[type="number"] {
            height: 40px;
            border: 1px solid #d1d1d1;
            border-radius: 4px 0 0 4px;
            color: #b6b6b6;
        }
        
        .qty-select__button {
            color: #b6b6b6;
            
            &:last-child {
                border-radius: 0 4px 0 0;
            }
            
            &:first-child {
                border-radius: 0 0 4px 0;
            }
        }
    }
}

.product-item {
    .add-to-favourite {
        font-size: 14px;
        color: #606060;
    }
    
    .add-to-links {
        display: flex;
    }
}

@include min-screen($screen__m) {
    .catalog-category-view {
        .column.main {
            padding-left: 50px;
        }
    }
    
    .actions-wrapper {
        .qty-select {
            > input[type="number"] {
                width: 50px;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    
    .actions-wrapper {
        flex-direction: column;
        
        .qty-select {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
    
    .product-item {
        .add-to-links {
            display: none;
        }
        
        .old-price {
            display: none;
        }
        
        .add-to-favourite {
            font-size: 12px;
        }
    }
    
    .toolbar {
        a {
            color: #9fa3ac;
            
            &:hover {
                color: #9fa3ac;
            }
        }
    }
}
