//
//  Variables
//  _____________________________________________

$checkout-summary__background-color : $color-white-smoke !default;
$checkout-summary__padding          : 22px $indent__l !default;

$checkout-summary-title__margin     : $indent__s !default;
$checkout-summary-mark-value__color : $color-gray60 !default;

$checkout-summary-items__max-height : 370px !default;
$checkout-summary-items__padding    : 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary {
    @extend .abs-add-box-sizing;
    @include lib-css(background, $checkout-summary__background-color);
    @include lib-css(padding, $checkout-summary__padding);
    margin: 0 0 $indent__base;

    > .title {
        @extend .abs-checkout-title;
        display: block;
    }

    //  Totals table
    .table-totals {
        @extend .abs-sidebar-totals;
    }

    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .items-in-cart {
        > .title {
            border-bottom: $border-width__base solid $border-color__base;
            @include lib-css(padding, $indent__s $indent__xl $indent__s 0);
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size        : 12px,
                $_icon-font-line-height : 12px,
                $_icon-font-text-hide   : true,
                $_icon-font-margin      : 3px 0 0,
                $_icon-font-position    : after,
                $_icon-font-display     : block
            );
            margin-bottom: 0;
            position: relative;
            z-index: 25;
            background-color: #f5f5f5;
            
            &:after {
                position: absolute;
                right: 0;
                top: $indent__s;
            }

            strong {
                @include lib-font-size(18);
                font-weight: $font-weight__light;
                margin: 0;
            }
        }

        &.active {
            > .title {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .product {
            position: relative;
        }
        
        .product-item {
            padding-top: 25px;
            
            &-name {
                font-size: 16px;
            }
        }
    
        .details-qty {
            margin-top: -10px;
        
            .value {
                font-size: 15px;
            }
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        @include lib-css(margin, 0 -($checkout-summary-items__padding) 0 0);
        @include lib-css(max-height, $checkout-summary-items__max-height);
        @include lib-css(padding, $checkout-summary-items__padding $checkout-summary-items__padding 0 0);
        border: 0;
    }

    .column.main & {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }

    .product-item {
        .product-item-inner {
            display: table;
            width: 100%;
        }

        .product-item-name-block {
            display: table-cell;
            padding-right: $indent__xs;
            text-align: left;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            @include lib-font-size(16);
            font-weight: $font-weight__regular;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    @include lib-font-size(10);
                }
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
