.footer-columns {
    a {
        color: $footer-link-color;
    }

    .footer-column {
        padding-right: 70px;

        &__logo {

            img {
                height: 75px;
                width: 100%;
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer.content {
        .footer-columns {
            nav {
                flex: 1;
                padding-right: $indent__l;
            }

            ul.level0 {
                display: flex;
                justify-content: space-between;

                > li:first-child {
                    padding-right: 50px;
                }
            }

            li.level1 {
                display: block;
                margin-bottom: 10px;

                &:first-of-type {
                    margin-top: 10px;
                }
            }

            li.level0 > a {
                font-weight: $font-weight__semibold;
            }
        }
    }
}

@include max-screen($screen__m) {
    .footer.content {
        padding-top: 0;

        .footer-columns {
            nav {
                margin-bottom: 25px;
            }

            ul ul {
                display: none;
            }

            li.level0 {
                margin-left: -$layout__width-xs-indent;
                margin-right: -$layout__width-xs-indent;
                border-bottom: 1px solid $border-color__base;
            }

            a.level-top {
                @include lib-icon-font(
                    $_icon-font-content: $icon-next,
                    $_icon-font-position: after,
                    $_icon-font-display: flex
                );
                display: flex;
                justify-content: space-between;
                padding: 15px $layout__width-xs-indent;
            }
        }
    }

    .footer-columns .footer-column {
        padding-right: 0;
    }
}
