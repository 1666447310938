
.search-autocomplete {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    margin-top: 10px;
    box-shadow: 0px 21px 17px -20px rgba(0, 0, 0, 0.5);
    @extend .abs-add-box-sizing;

    dl {
        @include lib-list-reset-styles();

        dt {
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, $autocomplete__border);
                border-top: 0;
                border-bottom: 0;
            }

            @include lib-css(border-top, $autocomplete-item__border);
            cursor: default;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:first-child {
                border-top: none;
                padding-top: 10px;
                padding-bottom: 10px;
                color: $text__color;
            }

            &:not(.autocomplete-list-title):hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }
        }

        &:first-child dt:first-child:not(:empty) {
            @include lib-css(border-top, $autocomplete__border);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-child dd:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        dd {
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, $autocomplete__border);
                border-top: 0;
            }

            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }

            .clear {
                clear: both;
            }
        }
    }
}

.nav-sections .navigation .cat-icon {
    &.active {
        a:before {
            content: url('../images/cat-icon-dark.svg');
        }
    }

    a:before {
        content: url('../images/cat-icon.svg');
        display: inline-block;
        line-height: normal;
        transform: translateY(3px);
        margin-right: 8px;
    }

    a:hover:before {
        content: url('../images/cat-icon-dark.svg');
    }
}
