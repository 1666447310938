//
//  Product Lists
//  _____________________________________________

.related,
.upsell,
.crossell {
    margin: 20px 0;

    .title {
        font-size: 20px;
        border-bottom: 1px solid lightgrey;
    }

    .product-item-name {
        min-height: 57px;
    }

    .product-item-info {
        justify-content: flex-start;
    }
}

.column.main {
    .products-grid {
        .product {
            &-items {
                margin: 0 -5px !important;
            }

            .product-item-details {
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: space-between;
            }
        }

        // Overwrite !important from Mageplaza_Shopbybrand
    }
}

.products {
    margin: $indent__l 0;

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
        }
    }
}

.products-list {
    .product-item-name {
        min-height: auto;
    }

    .actions-primary {
        max-width: 200px;
        margin-bottom: 15px;
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        font-size: 20px;

        .products-grid & {
            display: inline-block;
            width: 50%;
            padding: 0 5px;
        }

        &-name {
            display: block;
            min-height: 75px;
            margin: $indent__xs 0;
            word-wrap: break-word;
            hyphens: auto;
            font-size: 18px;

            a {
                color: $theme-light-green1;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            min-height: 100%;
        }

        &-actions {

            .action.primary {
                width: 100%;
            }

            .actions-primary {
                width: 100%;
                margin-bottom: 10px;
            }

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;

                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description,
        span {
            a {
                font-size: 14px;
                padding: 10px 0;
            }
        }

        .product-reviews-summary {
            .rating-summary {
                display: none;
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            display: flex;
            flex-direction: column-reverse;

            .old-price {
                .price {
                    font-size: 16px;
                }
            }

            .price {
                font-size: 20px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
                color: $price-color;
            }

            .price-label {
                font-size: $font-size__s;
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 22px;
                font-weight: $font-weight__bold;
                color: $price-color;
                padding: 15px 15px 15px 0;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            color: $theme-color-orange1;

            .price {
                font-weight: $font-weight__regular;
                color: #eb661b;
                padding: 15px 15px 15px 0;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &__label {
                font-size: 12px;
            }

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price-wrapper {
                font-size: 20px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
                color: $price-color;
            }
        }

        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            min-width: 114px;
            white-space: nowrap;
        }
    }

    .product-item-description {
        font-size: 15px;
        padding: 10px 0;
    }
}

.no-show {
    opacity: 0;
}

.products {
    .price-container {
        display: flex;
        align-items: center;
    }

    .price-subtitle {
        font-size: 12px;
        font-weight: 400;
        margin: 0 0 0 5px;
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '(' attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            width: 100%;

            &.item {
                padding-left: 0;
            }

            &-info {
                display: flex;
                flex-direction: row;
                padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
                align-items: flex-end;
            }

            &-photo {
                padding: 0 $indent__l $indent__l 0;
            }

            &-details {
                flex: 1;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .actions-wrapper {
        margin-bottom: 10px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .widget-new-grid {
        @include product-carousel();
    }

    .product-item-info {
        margin-bottom: 20px;
    }
}

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .product-reviews-summary,
    .product-item-details [class^=swatch] {
        display: none;
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
                margin-right: $indent__l;
            }

            &-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .products-grid & {
                    margin: $indent__s 0 0 0;
                }

                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 25%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: (100% / 6);
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .product-item {
                padding: 0;
                width: 21.233%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
