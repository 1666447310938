.homepage-usps-wrapper {
    display: flex;

    &__usp {
        box-sizing: border-box;
        column-gap: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .inner-wrapper {
            width: 100%;
        }

        &:last-child {
            padding: 0;
        }
    }

    .image {
        border-radius: 50%;
        margin-bottom: 25px;
        width: 210px;
        height: 210px;
        background-position: center;
        background-size: cover;
    }

    h2 {
        margin: 0 0 10px 0;
        color: $brand__primary__color;
        text-transform: uppercase;
        font-weight: 800;
        text-align: center;
        padding: 0 30px;
    }

    p {
        text-align: center;
        color: #6d6d6d;
        line-height: 2.2;
        padding: 0 19px;
    }

    a:hover {
        text-decoration: none;
    }
}

//
//  tablet / mobile
//  _____________________________________________

@include max-screen($screen__l) {
    .homepage-usps-wrapper {
        flex-direction: column;

        &__usp {
            flex-direction: row;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

//
// Small mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .homepage-usps-wrapper {
        padding: 0 15px;

        &__usp {
            padding: 0;
        }

        h2,
        p {
            text-align: left;
            padding: 0;
        }

        .image {
            display: none;
        }
    }
}
