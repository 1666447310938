.product-attachments-container {
    margin-top: $indent__xl;
    margin-bottom: $indent__xl;
}

.product-attachment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px;
    border-bottom: 1px solid $border-color__base;

    &:first-child {
        border-top: 1px solid $border-color__base;
    }

    &__icon {
        display: flex;
        margin-right: 15px;
    }

    &__anchor {
        display: flex;
        align-items: center;
        margin-right: $indent__xs;
    }

    &__anchor,
    &__size {
        color: $color-gray20;
    }

    &__anchor {
        &:hover,
        &:visited {
            color: $color-gray20;
        }
    }

    &__img {
        max-width: 25px;
    }
}

@include max-screen($screen__m) {
    .product-attachments-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .product-attachments {
        margin-left: -$layout-indent__width;
        margin-right: -$layout-indent__width;

        .product-attachment {
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
        }
    }
}
