.login-modal {
    background: rgba(0, 0, 0, 0.3);
    
    .modal-header {
        padding-bottom: 10px;
    }
    
    .modal-title {
        @include lib-typography(
            $_font-size: 30px,
            $_font-family: $heading__font-family__base,
            $_font-weight: $heading__font-style__base
        );
        margin-bottom: 0;
        padding-bottom: 0;
        text-align: center;
        border-bottom: 0;
    }
    
    .modal-title-subtitle {
        text-align: center;
    }
    
    .modal-inner-wrap {
        max-width: 550px;
        border-radius: 6px ;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
        overflow: hidden;
    }
    
    .block-customer-login {
        margin-bottom: 0;
    }
    
    .field:not(:last-child) {
        margin-bottom: 15px;
    }
    
    .modal-content {
        padding: 0;
    }
    
    .block-content {
        
        &.right-block {
            padding: 40px;
            background-color: #eee;
        }
        
        &.left-block {
            margin: 20px 0;
            padding: 20px 100px;
        }
        
        .primary {
            width: 100%;
        }
        
        .action.login {
            width: 100%;
            padding: 12px 0;
        }
    }
    
    .action-close {
        padding: 15px;
    }
}
