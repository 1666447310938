.pages {
    @include lib-pager();

    .action {
        &.previous {
            margin-right: 10px;
        }

        &.next {
            margin-left: 10px;
        }
    }
}
