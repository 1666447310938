$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 14px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.icon-wrapper {
    display: flex;

    .list-tier-price-wrapper {
        .tier-list-toggle {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background-color: $color-gray-middle4;
            color: $color-white;
            text-align: center;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 3px 0 0;
        }

        .tier-list-button {
            position: absolute;

            .price-tier-list {
                background-color: $color-white;
                border: 1px solid;
                border-radius: 2px;
                border-color: $border-color__base;
                padding: 5px 5px 5px 5px;
                transform: translateY(20%);
            }
        }
    }
    .product-choice-wrapper {
        display: flex;
        flex-direction: row;

        .dot {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 7px;
        }

        .green {
            background-color: $accept__color;
        }

        .orange {
            background-color: $theme-color-orange1;
        }

        .red {
            background-color: $color-red10;
            text-align: center;
            color: $color-white;
            font-size: $font-size__l;
        }
    }
}

.tier-price-buttons {
    display:flex;
    flex-direction:row;
    width: 100%;
    align-items: center;

    .submit-title {
        margin: 0;
        white-space: nowrap;
        font-size: 14px;
    }
}



.old-price,
.old.price {
    color: $text__color__muted;

    .price {
        text-decoration: line-through;
        color: $text__color__muted;
    }
}

.prices-tier {
    .price-container {
        display: flex;
        align-items: center;

        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: flex;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }

    .item {
        display: flex;
        align-items: center;
    }
}

.product-item-info .product-choice-wrapper .product-item-inner, .column .product-info-main {
    .quick-order {
        cursor: pointer;
        margin-bottom: 12px;

        a {
            font-size: 14px;
            color: #606060;
        }

        .add-to-fast-order {
            display: flex;
        }

        .remove-from-fast-order {
            @include lib-icon-font(
                $_icon-font-content: $icon-remove,
                $_icon-font-color: $brand__secondary__color,
                $_icon-font-size: 13px,
                $_icon-font-margin: 0 5px 0 0
            );

            display: none;
        }

        &.in-list {
            .add-to-fast-order {
                display: none;
            }

            .remove-from-fast-order {
                display: flex
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    width: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;
    margin-bottom: 20px;

    .additional-attributes {
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .price-subtitle {
        margin-top: 10px;
    }

    .stock {
        &.available,
        &.third-party,
        &.unavailable {
            margin-bottom: $indent__s;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                margin-bottom: $indent__s;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-bottom: $indent__s;

        .old-price {
            display: block;
            margin-bottom: 15px;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include lib-font-size(14);
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                @include lib-font-size(14);
            }
        }

        .price-wrapper .price {
            @include lib-font-size(18);
        }

        .price-wrapper[data-price-type=oldPrice] .price {
            @include lib-font-size(14);
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;
        color: $brand__primary__color;

        .price-container {
            @include lib-font-size(14);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .price-box {
        .price-wrapper[data-price-type=finalPrice] {
            font-weight: $font-weight__bold;
        }
    }

    .add-to-fast-order {
        display: block;
        margin-bottom: $indent__base;
    }

    .box-tocart {
        margin: $indent__base 0 0;

        .fieldset {
            display: flex;
            margin: 30px 0 0;
        }

        .field.qty {
            padding-right: 0.75 * $indent__base;
            button {
                color: #d1d1d1;
                &:hover {
                    color: inherit;
                }
            }
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 22px;
            height: $tocart-input-size;
            text-align: center;
            width: 50px;
        }

        .actions {
            flex: 1;
            vertical-align: bottom;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: 0 0 $indent__base;
    }

    .action.tocompare {
        @extend .abs-actions-addto;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee,
    .benefit {
        display: flex;
        padding: 0 3px;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
            padding-right: 3px;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.product-usps {
    @extend .abs-usps;
    margin: 0 0 20px 0;
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            position: relative;
        }

        .product-item:not(:last-of-type) {
            margin-bottom: $indent__base;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.catalog-product-view {
    .page-title-wrapper {
        .page-title {
            margin-bottom: 40px;
        }
    }

    .page-sub-title {
        margin-bottom: $indent__base;

        .product.sku {
            display: flex;
            margin-top: 6px;

            .type {
                margin-right: 5px;
            }
        }

        .rating-summary {
            margin-right: 5px;
        }
    }

    .product-reviews-summary {
        margin-bottom: 0;
    }
}

.stock {
    &.available {
        color: $accept__color;
    }

    &.third-party {
        color: $theme-color-yellow2;
    }

    &.unavailable {
        color: $error__color;
    }
}

.special-price {
    .price-label {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product.info.detailed {
        .additional-attributes {
            * {
                display: block;
            }

            tbody > tr > th {
                padding-bottom: 0;
            }

            tbody > tr > td {
                padding-left: 0;
            }
        }
    }

    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product.info.detailed {
        .additional-attributes {
            table-layout: fixed;

            tbody > tr > th {
                position: relative;
                width: 190px;
                padding-right: 20px;
                font-weight: $font-weight__regular;

                &::after {
                    content: ':';
                    position: absolute;
                    right: 5px;
                }
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }

        .price-box .price-wrapper[data-price-type=finalPrice] .price {
            font-size: 34px;
        }
    }

    .catalog-product-view {
        .page-sub-title {
            display: flex;
            align-items: center;

            > div:not(:last-child) {
                &::after {
                    content: '|';
                    margin: 0 8px;
                    color: $primary__color__light;
                }
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        margin: 0;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__l) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            .action.tocart {
                @include lib-icon-font(
                    $icon-expand,
                    $_icon-font-size: 14px,
                    $_icon-font-margin: 0 $indent__xs 0 0,
                    $_icon-font-vertical-align: top
                );
                border-radius: 100px;
            }
        }
    }
}

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
        margin-bottom: $indent__m;
    }

    .page-layout-1column {
        .product-info-main {
            width: 50%;
        }
        .product.media {
            width: 47%;
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 46%;
        }

        .product.media {
            width: 45%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: 0;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.category-description-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.category-description {
    max-height: none;
    position: relative;
    line-height: 1.57;

    &:not(.collapsed) {
        .toggle-description {
            margin-top: -15px;
        }
    }

    &.collapsed {
        flex: 1;
        max-height: 3em;
        overflow: hidden;
    }
}

.toggle-description {
    align-self: flex-end;
    margin-top: -15px;
    margin-bottom: 20px;
    display: inline-block;
    white-space: nowrap;
}

@media only screen and (max-width: 1230px) and (min-width: 1023px)  {
    .actions-wrapper .action.secondary {
        padding: 7px 10px;
    }
}
