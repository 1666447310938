//
//  Variables
//  _____________________________________________

$minicart__border-color: $color-gray80 !default;
$minicart__padding-horizontal: $indent__base !default;

$minicart-qty__height: 24px !default;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
    .items-total {
        display: none;
        float: left;
        margin: 0 $indent__s;

        .count {
            font-weight: $font-weight__bold;
        }
    }

    .subtotal {
        margin: 0 $indent__s $indent__s;
        text-align: center;

        .label {
            @extend .abs-colon;
        }
    }

    .amount {
        .price-wrapper {
            &:first-child {
                .price {
                    font-size: $font-size__l;
                    font-weight: $font-weight__bold;
                }
            }
        }
    }

    .subtitle {
        display: none;

        &.empty {
            display: block;
            font-size: 14px;
            padding: $indent__l 0 $indent__base;
            text-align: center;
        }
    }

    .text {
        &.empty {
            text-align: center;
        }
    }

    .block-content {
        display: flex;
        flex-direction: column-reverse;

        > .actions {
            text-align: center;

            > .primary {
                margin: 20px $indent__s 15px $indent__s;

                .action {
                    &.primary {
                        @extend .abs-button-l;
                        display: block;
                        margin-bottom: 15px;
                        width: 100%;
                        background-color: $brand__primary__color;
                        border-radius: 4px;
                        border-bottom: 0;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            > .secondary {
                padding: 18px 0;

                a {
                    color: $brand__primary__color;
                    font-weight: $font-weight__semibold;
                }
            }
        }
    }

    .block-category-link,
    .block-product-link,
    .block-cms-link,
    .block-banners {
        margin: 15px 0 0;
        text-align: center;
    }
}

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector: '.action.showcart',
        $_options-selector: '.block-minicart',
        $_dropdown-toggle-icon-content: $icon-cart,
        $_dropdown-toggle-active-icon-content: $icon-cart,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_icon-font-position: before,
        $_icon-font-size: 24px,
        $_icon-font-color: $minicart-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-color-active: $minicart-icons-color,
        $_icon-font-vertical-align: 'middle'
    );

    .product-item-name {
        min-height: auto;
    }


    .block-minicart {
        @include lib-css(padding, 0 $minicart__padding-horizontal 25px $minicart__padding-horizontal);
        right: 0;
        width: 320px;
        z-index: 101;

        .block-title {
            display: none;
        }

        &:after {
            left: auto;
            right: 17px;
        }

        &:before {
            left: auto;
            right: 18px;
        }
    }

    .product {
        .actions {
            position: relative;
            top: -8px;
            float: right;
            margin: -24px 0 0;
            text-align: right;

            > .primary,
            > .secondary {
                display: inline;

                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }

    .action {
        &.close {
            @include lib-button-icon(
                $_icon-font-content: $icon-remove,
                $_icon-font-size: 16px,
                $_icon-font-line-height: 16px,
                $_icon-font-text-hide: true,
                $_icon-font-color: $brand__primary__color,
                $_icon-font-color-hover: $brand__primary__color,
                $_icon-font-color-active: $brand__primary__color
            );
            @include lib-button-reset();
            height: 40px;
            position: absolute;
            right: 0;
            top: 9px;
            width: 40px;
        }

        &.showcart {
            white-space: nowrap;
            position: relative;

            .text {
                @extend .abs-visually-hidden;
            }

            .counter.qty {
                @include lib-css(background, $brand__secondary__color);
                @include lib-css(color, $page__background-color);
                @include lib-css(height, 16px);
                @include lib-css(line-height, 16px);
                width: 26px;
                display: inline-block;
                min-width: 18px;
                overflow: hidden;
                text-align: center;
                white-space: normal;
                position: absolute;
                border-radius: 20px;
                left: 10px;
                top: -6px;
                font-size: 12px;


                &.empty {
                    display: none;
                }

                .loader {
                    > img {
                        @include lib-css(max-width, $minicart-qty__height);
                    }
                }
            }

            .counter-label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .minicart-widgets {
        margin-top: 15px;
    }
}

.minicart-items-wrapper {
    @include lib-css(border, 1px solid $minicart__border-color);
    @include lib-css(margin, 0 (-$minicart__padding-horizontal));
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 15px;
}

.minicart-items {
    @include lib-list-reset-styles();

    .product-item {
        padding: 15px 0;

        &:not(:first-child) {
            @include lib-css(border-top, 1px solid $minicart__border-color);
        }

        &:first-child {
            padding-top: 0;
        }

        > .product {
            @extend .abs-add-clearfix;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .product-item-pricing {
        .label {
            display: inline-block;
        }
    }

    .price-minicart {
        margin-bottom: $indent__xs;
    }

    .product {
        > .product-item-photo,
        > .product-image-container {
            float: left;
        }

        .toggle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 12px,
                $_icon-font-line-height: 12px,
                $_icon-font-text-hide: false,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            cursor: pointer;
            position: relative;

            &:after {
                @include lib-css(color, $color-gray56);
                position: static;
                margin: 0 0 0 $indent__xs;
            }
        }

        &.active {
            > .toggle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }
    }

    .product-item-name {
        font-size: 18px;
        font-weight: $font-weight__regular;
        margin: 0 0 $indent__s;

        a {
            @include lib-css(color, $theme-light-green1);
        }
    }

    .product-item-details {
        padding-left: 88px;

        .price {
            font-size: 16px;
            color: $price-color;
        }

        .price-including-tax,
        .price-excluding-tax {
            margin: $indent__xs 0 0;
        }

        .weee[data-label] {
            @include lib-font-size(11);

            .label {
                @extend .abs-no-display;
            }
        }

        .details-qty {
            margin-top: $indent__s;
        }
    }

    .product.options {
        .tooltip.toggle {
            @include lib-icon-font(
                    $icon-down,
                $_icon-font-size: 28px,
                $_icon-font-line-height: 28px,
                $_icon-font-text-hide: true,
                $_icon-font-margin: -3px 0 0 7px,
                $_icon-font-position: after
            );

            .details {
                display: none;
            }
        }
    }

    .details-qty,
    .price-minicart {
        .label {
            @extend .abs-colon;
            font-size: 16px;
            color: $price-color;
        }
    }

    .item-qty {
        margin-right: $indent__s;
        text-align: center;
        height: 34px;
        width: 40px;
        color: #b6b6b6;
        border: 1px solid #d1d1d1;
        border-radius: 4px;
    }

    .update-cart-item {
        @include lib-font-size(11);
        vertical-align: top;
    }

    .subtitle {
        display: none;
    }

    .action {
        &.edit,
        &.delete {
            @include lib-icon-font(
                    $icon-edit,
                $_icon-font-size: 18px,
                $_icon-font-line-height: 20px,
                $_icon-font-text-hide: true,
                $_icon-font-color: $minicart-icons-color,
                $_icon-font-color-hover: $primary__color,
                $_icon-font-color-active: $minicart-icons-color
            );
        }

        &.delete {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-trash
            );
        }
    }

    .product.options {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
    .minicart-wrapper .block-minicart {
        width: 290px;
    }
}

@include max-screen($screen__s) {
    .minicart-wrapper {
        margin-top: $indent__s;
    }
}

@include max-screen($screen__m) {
    .minicart-wrapper {
        .block-minicart {
            &:after {
                left: auto;
                right: 1px;
            }

            &:before {
                left: auto;
                right: 2px;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .minicart-wrapper {
        .block-minicart {
            right: -15px;
            width: 390px;
        }
    }
}
