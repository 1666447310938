.product-order-list {
    .control {
        float: left;
    }
}

.product-order-list {
    &__search-container {
        position: relative;
        margin: 0 0 40px;
        max-width: 450px;
    }

    table > tbody > tr > td {
        vertical-align: middle;
    }

    .qty-select {
        &__button {
            &--increase, &--decrease {
                &:before {
                    margin: 2px 0 0 9px;
                }
            }
        }
    }

    &__search-results {
        @include lib-list-reset-styles();
        box-sizing: border-box;
        position: absolute;
        top: calc(100% + 10px);
        width: 100%;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid  #e2e5ed;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        z-index: 2;

        > li {
            margin: 0;

            &:not(:last-child) {
                border-bottom: 1px solid  #e2e5ed;
            }

            &:hover {
                background-color: #f6f6f6;
            }

            .price {
                float: right;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 600;
                color: #1073b7;
            }

            span {
                margin-left: auto;
                color: $color-dark-green1;
            }
        }
    }

    &__search-item {
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        cursor: pointer;

        &.in-list {
            background-color: #f6f6f6;
        }
    }

    &__product {
        width: 48%;
    }

    &__product-title {
        @extend .abs-product-link;
    }

    &__image {
        max-width: 100px;
    }

    &__item {
        border-bottom: 1px solid $border-color__base;

        &:first-child {
            border-top: 1px solid $border-color__base;
        }
    }

    &__action {
        button {
            padding: 13px 15px;
            border-color: #e7e7e7;

            &.tocart {
                padding: 13px 15px;
                white-space: nowrap;
            }

            &.unavailable {
                opacity: 0.6;
            }
        }
    }

    &__subtotal {
        .price-wrapper {
            .price {
                white-space: nowrap;
                font-size: 16px;
                font-weight: 600;
                color: #1073b7;
            }
        }
    }

    td {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.product-order-list {
    &__body &__search {
        display: none;
    }
}

@include min-screen($screen__m) {
    .product-order-list {
        &__search-item {
            display: flex;
            justify-content: space-between;
        }
    }
}

@include max-screen($screen__m) {
    .product-order-list {
        &__item {
            display: flex;
            flex-wrap: wrap;

            > * {
                box-sizing: border-box;
            }
        }

        &__image-container {
            width: 30%;
        }

        &__product {
            width: 70%;
        }
    }
}
