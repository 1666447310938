.qty-select {
  position: relative;
  padding-right: 29px;

  &__button {
    position: absolute;
    right: 0;
    height: 50%;
    width: 30px;
    padding: 0;
    box-sizing: border-box;
    @include lib-button-reset();
    border: 1px solid $border-color__base;
    font-size: 1.4rem;
    line-height: 1.6rem;

    &:hover,
    &:focus {
      border: 1px solid $border-color__base;
    }

    &--increase {
      top: 0;
      border-radius: 0 $form-element-input__border-radius 0 0;
      @include lib-icon-font(
              $_icon-font-content     : $icon-up,
              $_icon-font-size        : 10px,
              $_icon-font-text-hide   : true
      );
    }

    &--decrease {
      bottom: 0;
      border-top: 0;
      border-radius: 0 0 $form-element-input__border-radius 0;
      @include lib-icon-font(
              $_icon-font-content     : $icon-down,
              $_icon-font-size        : 10px,
              $_icon-font-text-hide   : true
      );

      &:hover,
      &:focus {
        border-top: 0;
      }
    }
  }

  input[type="number"] {
    border-radius: $form-element-input__border-radius 0 0 $form-element-input__border-radius;
  }
}
