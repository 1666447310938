.home-content {
    margin: 60px 0;

    &__section {
        &:not(:first-child) {
            margin-top: 30px;
        }

        &.feline-favourites {
            .inner-left {
                background-image: url('../images/feline-backdrop.png');
                background-position: 255px 40px;
                background-repeat: no-repeat;

                .cms-link-button {
                    &:after {
                        content: url('../images/cat-icon-dark.svg');
                        filter: invert(1) brightness(2);
                        line-height: 1;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    &__image-left,
    &__image-right {
        display: flex;
        justify-content: space-between;
    }

    h2 {
        color: $brand__primary__color;
        text-transform: uppercase;
        font-weight: 800;
        margin: 0;
        padding-bottom: 10px;
    }

    p {
        line-height: 2.2;
        margin-bottom: 20px;
        padding: 0;
        color: #6d6d6d;
    }

    .inner-wrapper {
        height: 100%;

        .cms-link-button {
            box-sizing: border-box;
            display: flex;
            width: max-content;
            align-items: center;
            height: 47px;
        }
    }

    .inner-right, .inner-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f5fafd;

        .inner-wrapper {
            padding: 45px;
        }

        .section-image {
            height: 100%;
            object-fit: cover;
        }
    }

    .brand-color-two {
        color: $brand__secondary__color;
    }

    .btn,
    .cms-link-button {
        padding: 15px;
        background: $brand__primary__color;
        color: white;
        border-radius: 50px;

        &:hover {
            background: lighten($brand__primary__color,2%);
            text-decoration: none;
        }
    }
}

//
//  mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .home-content {
        display: flex;
        flex-wrap: wrap;

        &__section {
            &.feline-favourites {
                .inner-left {
                    background-position: 100px 50%;
                }
            }
        }

        .inner-wrapper {
            padding: 50px 20px;
        }

        .inner-right,
        .inner-left {
            width: 100%;
            height: auto;
            justify-content: flex-start;
        }

        &__image-left,
        &__image-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        &__image-right {
            flex-direction: column-reverse;
        }
    }
}
