.fotorama__thumb-border {
    @extend .fotorama-print-background;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border: 1px solid $brand__primary__color !important;
    background-image: none;
    backface-visibility: hidden;
    z-index: 9;
    border-radius: 8px;

    .fotorama__nav--dots & {
        display: none;
    }

    .fotorama__wrap--css3 & {
        @extend .fotorama-transition-for-slide;
    }
}
