.post-list-item {
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color__base;

    &:not(:first-child) {
        padding-top: 20px;
    }

    .mp-post-title {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .mp-post-info {
        margin-bottom: 10px;
        color: $text__color__muted;
    }

    .post-link-title {
        color: $text__color;
    }
}

.blog-post-image {
    margin-bottom: 10px;

    img {
        border-radius: 5px;
    }
}

.list-post-tabs {
    margin-bottom: $indent__m;

    .create-at {
        margin-top: $indent__xs;
        color: $text__color__muted;
        font-size: 12px;
    }

    img {
        margin-bottom: $indent__xs;
    }

    a {
        color: $text__color;
    }
}

.autocomplete-suggestions {
    margin-top: $indent__xs;
    background-color: $color-white;
    border: 1px solid $border-color__base;
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.autocomplete-suggestion {
    padding: $indent__s;

    &:hover {
        cursor: pointer;
        background-color: darken($color-white, 2%);
    }
}

.autocomplete-suggestion:not(:last-child) {
    border-bottom: 1px solid $border-color__base;
}

.mp-sidebar {
    .block-title {
        @extend .abs-block-title;
    }

    [class*="menu-"] {
        @extend .abs-reset-list;

        i {
            display: none;
        }

        a {
            color: $text__color__muted;
        }
    }
}

.mpblog-search {
    .rel {
        position: relative;
    }

    .action.search {
        display: inline-block;
        @include lib-button-icon(
            $_icon-font-content   : $icon-search,
            $_icon-font-text-hide : true,
            $_icon-font-color     : $header-icons-color,
            $_icon-font-size      : 16px,
            $_icon-font-line-height : 32px
        );
        @include lib-button-reset();
        padding: 0;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;

        &:focus {
            &:before {
                @include lib-css(color, $color-gray20);
            }
        }
    }
}

.post-list-container {
    &__widget-title {
        @include lib-heading(h3);
    }

    &__post-info {
        margin-bottom: 10px;
        color: $text__color__muted;
    }

    &__post-title {
        margin: $indent__s 0;
        color: $text__color;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .post-list-container {
        &__row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -10px;
            margin-left: -10px;
        }

        &__item {
            box-sizing: border-box;
            width: (100% / 3);
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

//
//  Mobile and tablet
//  _____________________________________________

@include max-screen($screen__l) {
    .post-list-container {
        display: none;
    }
}
