.actions-toolbar {
    > .primary,
    > .secondary {
        text-align: center;
        .action {
            width: 100%;
            margin-bottom: $indent__s;
            &:last-child {
                margin-bottom: 0;
            }
        }
        margin-bottom: $indent__s;
        &:last-child {
            margin-bottom: 0;
        }
    }

    > .secondary {
        .action.back {
            display: none;
        }
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .actions-toolbar {
        @include lib-actions-toolbar();
        margin: 20px 0;
        > .secondary,
        > .primary {
            margin-bottom: 0;
            .action {
                margin-bottom: 0;
                width: auto;
            }
        }
    }
}
