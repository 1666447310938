//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.panel.header {
    .topbar__list,
    .switcher {
        display: none;
    }
}

.panel.wrapper {
    background-color: $brand__primary__color;
}

.nav-sections {
    @include lib-css(background, $navigation__background);
}


.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size        : 28px,
        $_icon-font-color       : $header-icons-color,
        $_icon-font-color-hover : $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

.nav-toggle-mobile {
    @include lib-icon-font(
            $icon-remove,
        $_icon-font-size        : 16px,
        $_icon-font-color       : $header-icons-color,
        $_icon-font-color-hover : $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    position: absolute;
    top: 10px;
    right: 15px;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: 17px;
                    top: 12px;
                    @include lib-font-size(14);
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content  : $icon-up,
                        $_icon-font-position : after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        padding-top: 30px;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .section-item-content:not(#store\.menu) > ul {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    display: none;
                    border-top: 1px solid $color-gray82;
                    border-bottom: 1px solid $color-gray82;
                    font-weight: $font-weight__regular;
                    padding: $navigation-level0-item__padding;
                }

                &.customer-welcome {
                    .customer-name {
                        display: none;
                    }
                }

                > a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                @include lib-css(color, $navigation-level0-item__color);
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: $font-weight__regular;
                padding: $navigation-level0-item__padding;
            }

            .header.links {
                border: 0;
                list-style: none;
                padding-left: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
        .usps {
            display: none;
        }
    }

    .nav-sections-item-title {
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 1.6rem;

        .nav-sections-item-switch {
            font-weight: $font-weight__semibold;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        box-sizing: border-box;
        padding: 0 0 40px 15px;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle-mobile {
        display: none;
    }

    .nav-toggle {
        display: none;
    }

    .nav-sections {
        @include lib-vendor-prefix-flex-shrink(0);
        @include lib-vendor-prefix-flex-basis(auto);
        @include lib-css(background, $navigation-desktop__background);
        margin-bottom: $indent__m;
        background-color: #f6fafd;

        &-items {
            margin-left: auto;
            margin-right: auto;
            max-width: 1210px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: flex;
        justify-content: space-between;
    }

    .main-logged-in-right__list {
        display: flex;
        justify-content: flex-end;
    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
            width: fit-content;
            margin: 0;

            .level0 {
                > .level-top {
                    color: #333;
                    font-size: 15px;
                }
            }

            &.main-logged-in-right {
                .level0 {
                    > .level-top {
                        color: $brand__primary__color;

                        &.active {
                            border-color: $brand__secondary__color;
                            border-style: solid;
                            border-width: 0 0 3px;
                        }
                    }
                }
            }
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {
        .topbar__list,
        .links,
        .switcher {
            display: inline-block;
        }
    }
}

@include screen($screen__m, $screen__l) {
    .nav-sections-item-content {
        > .navigation.main-logged-in-right {
            display: none;
        }
    }
}
