//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

//
//  Heading font
//  _____________________________________________

@include lib-font-face(
    $family-name: $heading__font-family__base,
    $font-path: '../fonts/brandon-grotesque/bold/BrandonGrotesque-Bold',
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $heading__font-family__base,
    $font-path: '../fonts/brandon-grotesque/black/BrandonGrotesque-Black',
    $font-weight: 900,
    $font-style: normal
);

//
//  Base font
//  _____________________________________________

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/proxima-nova/light/ProximaNova-Light',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/proxima-nova/regular/ProximaNova-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/proxima-nova/bold/ProximaNova-Bold',
    $font-weight: 700,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/proxima-nova/black/ProximaNova-Black',
    $font-weight: 900,
    $font-style: normal
);