.product-attachments {
    margin: 40px 0 40px 0;

    h3.title {
        text-transform: uppercase;
        font-weight: 800;
        margin: 0 0 10px;
        color: #308b8f;
    }

    ul {
        padding: 0;
    }

    li {
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        list-style-type: none;
        padding: 15px 5px;

        a {
            color: #6d6d6c;
        }
    }
}

@media (max-width: 768px) {
    .product-attachments {
        margin: 20px 0 20px 0;
    }
}
