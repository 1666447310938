.slider-text {
    display: flex;
    flex-direction: row;
    padding-top: 40px;

    &__left {
        width: 60%;

        p {
            margin-top: -5px;
            color: $brand__primary__color;
            font-size: 16px;
            line-height: 28px;
        }
    }

    &__right {
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-left: 25px;

        h3 {
            margin: 0;
            color: $brand__primary__color;
            text-transform: uppercase;
            font-weight: 800;
            margin-bottom: 10px;
        }

        .usp {
            padding-bottom: 5px;
            color: #6d6d6d;
        }

        .clock {
            @include lib-icon-font(
                $_icon-font-content: $icon-clock,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-display: inline-block,
                $_icon-font-color: #ed641c,
                $_icon-font-size: 22px
            );
        }

        .phone {
            @include lib-icon-font(
                $_icon-font-content: $icon-phone,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-display: inline-block,
                $_icon-font-color: #ed641c,
                $_icon-font-size: 22px
            );
        }

        .form {
            @include lib-icon-font(
                $_icon-font-content: $icon-comment,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-display: inline-block,
                $_icon-font-color: #ed641c,
                $_icon-font-size: 22px
            );
        }

        a {
            color: inherit;
        }

    }
}

@include max-screen($screen__m) {
    .slider-text {
        flex-direction: column;

        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
            padding: 0;
            margin-top: 20px;
        }
    }
}
