//  Newsletter subscription
.block.newsletter {
    margin-bottom: $indent__xl;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .level-top {
        color: $footer-link-color;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .form.subscribe {
        position: relative;
        display: table;
        width: 100%;
        overflow: hidden;
        border: 1px solid darken($color-white, 10%);
        border-radius: 40px;
        margin-top: 20px;
    }

    .fieldset {
        display: table-cell;
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {
            display: block;

            &:before {
                position: absolute;
            }
        }
    }

    input {
        padding-left: 15px;
        font-size: 15px;
        border: 0;
    }

    .title {
        display: none;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;
        position: relative;
        right: 0;
        top: 0;
    }

    .subscribe {
        position: absolute;
        top: 0;
        right: 0;

        span {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-size: 20px,
                $_icon-font-color: $brand__primary__color,
                $_icon-font-line-height: $icon-calendar__font-size,
                $_icon-font-display: block
            );
        }
    }

    .action.subscribe {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 14px;
        background-color: #f5fbfc;
        border: none;
        border-radius: 0;

        &:hover {
            background-color: darken(#f5fbfc, 3%);
        }

        &:active {
            box-shadow: none;
        }
    }

    .control {
        width: 100%;

        div.mage-error {
            position: absolute;
            right: 60px;
            bottom: 0;
            overflow: hidden;
            width: 20px;
            height: 40px;

            @include lib-icon-font(
                $_icon-font-content: $icon-warning,
                $_icon-font-size: 20px,
                $_icon-font-color: $error__color,
                $_icon-font-line-height: $icon-calendar__font-size,
                $_icon-font-display: block
            );
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.newsletter {
        width: 32%;

        input[type=email] {
            height: 40px;
            padding-left: 15px;
        }

        .field {
            margin-right: 5px;
        }
    }
}

@include max-screen($screen__m) {
    .newsletter {
        &__bottom {
            margin-top: 10px;
        }
    }

    .block.newsletter .subscribe span:before {
        line-height: 35px;
    }

    .block.newsletter .control div.mage-error {
        height: 35px;
    }
}
