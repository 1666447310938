// /**
//  * DISCLAIMER
//  *
//  * Do not edit or add to this file if you wish to upgrade Smile Elastic Suite to newer
//  * versions in the future.
//  *
//  *
//  * @category  Smile
//  * @package   Smile\ElasticsuiteCatalog
//  * @author    Aurelien FOUCRET <aurelien.foucret@smile.fr>
//  * @copyright 2016 Smile
//  * @license   Open Software License ("OSL") v. 3.0
//  */

.smile-es-range-slider {
    color: $color-white;

    [data-role=from-label] {
        display: block;
        float: left;
        padding: 0 0 5px;
    }

    [data-role=to-label] {
        display: block;
        float: right;
        padding: 0 0 5px;
    }

    [data-role=message-box] {
        flex: 1;
        padding: 6px 0 0;

        &.empty {
            @include lib-form-validation-note();
            padding: 0;
        }
    }

    .actions-toolbar {
        display: flex;
        margin: 15px 0;
    }

    .ui-slider {
        margin: 5px;
        clear: both;
    }

    .ui-slider-handle {
        padding: 0;
        margin: -3px 0 0 -7px;
        -webkit-border-radius: 15px;
        width: 15px;
        height: 15px;
        background: lighten($color-blue1, 10%);
    }
}

.filter {
    .block-subtitle {
        border-bottom: none;
        font-weight: bold;
    }

    .actions-toolbar {
        .action.primary.small {
            padding: 10px 12px;
            line-height: 9px;
            border-radius: 18px;
        }
    }
    
    .actions {
        margin: 10px 0 0;

        div.secondary {
            display: inline-block;

            a {
                cursor: pointer;

                &:hover {
                    background: transparent;
                }
            }
        }
    }

    .filter-options {
        dt.filter-options-title[aria-expanded="true"]:after {
            content: "\e621"
        }
    }

    .no-results-message p {
        margin: 9px 10px 0;
        background-color: #FFEEEE;
        padding: 5px;
        color: #AA0000;
    }
}

.smile-elasticsuite-autocomplete-result {
    .product-image-box {
        float: left;
        padding: 0 5px;
        width: 55px;
    }

    .product-shop {
        float: left;
        margin: 0 10px 0 0;
        padding: 0;
        white-space: normal;
        width: 60%;
    }
    
    .product-name {
        font-size: 15px;
        font-weight: 600;
    }
    
    .product-image-box {
        display: flex;
        align-items: center;
    }
    
    dl dd {
        display: flex;
        align-items: center;
    }
    
    .product-secondary {
        display: none;
    }

    .product-item {
        .price-box {
            margin: 5px 0;

            > span:not(.old-price) .price {
                @include lib-font-size(14);
            }
        }
    }

    .category-mini-crumb {
        font-style: italic;
        display: block;
        margin-bottom: 0.2em;
        font-size: 85%;
        color: #929292;
    }

    .product-attribute-label {
        text-transform: uppercase;
        vertical-align: super;
        font-size: 63%;
        color: #777;
    }

    .price-box {
        span.old-price {
            display:block;
        }
    }
}

@keyframes rotate {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.form.minisearch.processing {
    &::after {
        content: '';
        position: absolute;
        right: 42px;
        top: 3px;
        width: 40px;
        height: 40px;
        background-image: url('../images/loader.svg');
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        animation: rotate 0.75s linear infinite;
    }
}

@include min-screen($screen__m) {
    .page-layout-1column {
        .filter-options {
            .filter {
                &-options {
                    &-content {
                        padding: $indent__s;
                    }
                }
            }
        }
    }
}
