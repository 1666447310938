$toolbar-mode-icon-font-size: 16px;
$toolbar-background: transparent;
$toolbar-element-background: #fff;
$toolbar-element__color: $color-gray37;
$pager-action__background: $toolbar-element-background;
$pager-action__hover__background: darken($toolbar-element-background, 7%);
$pager__font-weight: $font-weight__bold;
$button__shadow-active: inset 0 1px 0 0 rgba($border-color__base, 0.8), inset 0 -1px 0 0 rgba($border-color__base, 0.3);

.page-products {
  .columns {
    position: relative;
    z-index: 1;
  }
}

.toolbar {
  @extend .abs-add-clearfix;
  margin-bottom: $indent__l;
  text-align: center;

  .limiter-text,
  .label {
    color: #a4adb4;
  }

  select {
    @include lib-css(background-color, $toolbar-element-background);
    border-radius: 3px;
    color: #9fa3ac;
    border: 1px solid #9fa3ac;
    padding: 0 30px 0 20px;
  }

  .sorter-label {
    color: #9fa3ac;
  }

  &-amount {
    left: 0;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 7px 0;
    text-align: left;
    top: 0;
    vertical-align: middle;

    .products.wrapper ~ .toolbar & {
      display: none;
    }
  }

  .page-with-filter & {
    &-amount {
      position: static;
    }
  }

  &-products {
    @include lib-css(background-color, $toolbar-background);

    .pages {
      display: none;

      .products.wrapper ~ & {
        display: block;
      }
    }
  }

  .pages {
    margin-bottom: $indent__m;
  }
}

.sorter {
  float: right;

  .products.wrapper ~ .toolbar & {
    display: none;
  }

  &-options {
    margin: 0 $indent__xs 0 7px;
    width: auto;
  }

  &-action {
    @include lib-icon-font(
                    $icon-arrow-up,
            $_icon-font-size: 16px,
            $_icon-font-color: $header-icons-color,
            $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();

    &.sort-desc:before {
      content: $icon-arrow-down;
    }
  }
}

.modes {
  display: none;
}

.limiter {
  &-options {
    margin: 0 5px 0 7px;
    width: auto;
  }

  &-label {
    font-weight: 400;
  }

  .page-products .toolbar & {
    display: none;
  }

  .control {
    display: inline-block;
  }
}

//
//  Mobile (small)
//  _____________________________________________

@include max-screen($screen__xs) {
  .sorter-label {
    display: none;
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .toolbar-products {
    margin-bottom: 0;
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .page-products {
    .columns {
      position: relative;
      z-index: 1;
    }
  }

  .toolbar-amount {
    display: block;
    float: left;
    position: static;
    color: #a4adb4;
  }

  .toolbar {
    .products.wrapper ~ & .pages {
      float: left;
      margin-bottom: 0;
    }
  }

  .modes {
    display: inline-block;
    float: left;
    margin-right: 10px;

    .products.wrapper ~ .toolbar & {
      display: none;
    }
  }

  .modes-label {
    @extend .abs-visually-hidden-desktop;
  }

  .modes-mode {
    @include lib-css(color, $toolbar-element__color);
    border-right: 0;
    float: left;
    font-weight: $font-weight__regular;
    line-height: 1;
    padding: 7px 10px 7px 0px;
    text-align: center;
    background: none;

    &:hover {
      @include lib-css(color, $brand__primary__color);
      color: $brand__primary__color;
    }

    &.active {
      @include lib-icon-font(
              $_icon-font-content: '',
              $_icon-font-size: 19px,
              $_icon-font-text-hide: true,
              $_icon-font-color: $brand__primary__color,
              $_icon-font-color-hover: darken($brand__primary__color, 10%)
      );
    }

    @include lib-icon-font(
            $_icon-font-content: $icon-grid,
            $_icon-font-size: 19px,
            $_icon-font-text-hide: true,
            $_icon-font-color: #a4adb4,
            $_icon-font-color-hover: darken($brand__primary__color, 10%)
    );
  }

  .sorter {
    .page-products & {
      position: static;
    }
  }

  .mode-list {
    &:before {
      content: $icon-list;
    }
  }

  .limiter {
    float: right;
    .products.wrapper ~ .toolbar & {
      display: block;
    }
  }

  .trigger-filters {
    .toolbar-products & {
      display: none;
    }
  }
}
