.product {
    
    .page-title {
        color: $brand__primary__color;
        text-transform: uppercase;
        font-size: 28px;
    }
}

.catalog-product-view {
    .sidebar-main {
        width: 36%;
    }
    
    .column.main {
        width: 64%;
    }
    
    .towishlist {
        @include lib-icon-font(
            $_icon-font-content: $icon-wishlist-full
        );
        margin-bottom: 20px;
    }
}

.grouped {
    .product-item-name {
        min-height: auto;
    }
    
    .price-box {
        margin-top: 20px;
    }
}