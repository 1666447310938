// NOTE: Please excuse the uses of !important, it can't be helped.

@include min-screen($screen__m) {
    .nav-sections {
        .navigation {
            li.level0 {
                position: static;

                > ul.level0 {
                    position: absolute;
                    right: 15px;
                    left: 15px !important;
                    padding: 15px 0;
                    @include abs-add-clearfix;
                }
            }

            li.level1 {
                float: left;
                width: (100% / 4); // You can change 4 to the amount of columns you want
            }

            li {
                margin-bottom: 0;
            }

            .submenu {
                position: static;
                min-width: auto;

                a {
                    display: inline-block;

                    &.ui-state-focus {
                        background-color: transparent;
                    }

                    &:hover {
                        background-color: transparent;
                        text-decoration: underline;
                    }
                }

                .submenu {
                    display: block !important;
                    position: static !important;
                    left: 0 !important;
                    box-shadow: none;
                    border: 0;
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .main__inner-item--parent {
        > a {
            font-weight: $font-weight__bold;
        }
    }
}

//
//  Debugging
//  ---------------------------------------------
// Use the following to forcefully show a menu
// Replace 12 with the menu #
//
//ul[data-menu=menu-12] {
//    display: block !important;
//}
