//
//  Theme variables
//  _____________________________________________

$z-index__sticky-header: 500 !default;
$z-index--interactive-banner: 10 !default;
$z-index__interactive-banner-active: 20 !default;

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

//  Header
$header__background-color: false !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;
$customer-welcome__z-index: $dropdown-list__z-index + 1 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: darken($addto-color, 5%) !default;

$addto-grid-color: $icon-font__color-faded !default;
$addto-grid-hover-color: darken($addto-grid-color, 5%) !default;

$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

//  Footer
$footer__background-color: false !default;

body {
    @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
}

.header {
    &.content {
        display: flex;
        flex-direction: column;
    }

    &__mobile-search {
        display: none;
    }

    &__content-wrapper {
        display: flex;
        flex: 1;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .authorization-link {
        a {
            @include lib-icon-font(
                $_icon-font-content: $icon-account,
                $_icon-font-size: 22px,
                $_icon-font-color: $minicart-icons-color,
                $_icon-font-color-hover : $header-icons-color-hover
            );
        }
    }

    &__minicart {
        display: flex;
        column-gap: 27px;
        align-items: center;
    }

    &__search {
        flex: 1;
        box-sizing: border-box;
        padding: 0 15px;

        .block-search {
            width: 100%;

            .block-content {
                display: flex;
                justify-content: center;
            }
        }

        .form.minisearch {
            position: relative;
            max-width: 690px;
            width: 100%;
        }
    }

    .block-search {
        input#search {
            &::placeholder {
                color: $color-gray56;
            }
        }

        .action.search {
            height: auto;
            background: none;
        }
    }

    &.panel {
        display: flex;
        justify-content: space-between;

        > .header.links {
            color: $color-white;
            @include lib-list-inline();

            > li {
                a {
                    font-size: 13px;
                }
            }

            .topbar-link {
                a:before {
                    margin-right: 8px;
                }
            }

            .topbar-link.icon-phone {
                a {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-phone,
                        $_icon-font-size: 14px
                    );
                }
            }

            .topbar-link.icon-msg {
                a {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-chat-bubble,
                        $_icon-font-size: 14px
                    );
                }
            }

            &.usps {
                li {
                    font-size: 13px;
                }
            }
        }
    }
}

.header__search-cart {
    display: flex;
    flex-direction: row-reverse;
}

.wishlist-header {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    position: relative;

    a {
        @include lib-icon-font(
            $_icon-font-content: $icon-add-to-list,
            $_icon-font-display: inline-block,
            $_icon-font-color: $minicart-icons-color,
            $_icon-font-color-hover : $header-icons-color-hover,
            $_icon-font-size: 19px
        );

        .counter.qty {
            @include lib-css(background, #308b8f);
            @include lib-css(color, $page__background-color);
            @include lib-css(height, 16px);
            @include lib-css(line-height, 16px);
            width: 26px;
            display: inline-block;
            min-width: 18px;
            overflow: hidden;
            text-align: center;
            white-space: normal;
            position: absolute;
            border-radius: 20px;
            left: 10px;
            top: -6px;
            font-size: 12px;

            &.empty {
                display: none;
            }

            .loader {
                > img {
                    @include lib-css(max-width, $minicart-qty__height);
                }
            }
        }

        .counter-label {
            @extend .abs-visually-hidden;
        }
    }
}

.logo {
    margin: 0 0 $indent__s $indent__xl;
    position: relative;
    z-index: 5;

    img {
        width: 206px;
        display: block;
    }

    .page-print & {
        float: none;
    }

    .logo-mobile {
        display: none;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

.abs-price-md {
    font-size: 14px;
}

.abs-cta-link {
    font-size: 14px;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 3;
        background-color: $color-white;

        .content {
            box-sizing: border-box;
            display: flex;
            max-width: $layout__max-width - $layout-indent__width * 2;
            margin: 0 auto;
            padding: $layout-indent__width;
            border-width: 1px 0 0 0;
            border-style: solid;
            border-color: $border-color__base;

            p {
                flex: 1;
            }
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
}

.footer {
    &.content {
        margin-top: 25px;
        padding-bottom: 25px;
        padding-top: 25px;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop (Cookie message max-width)
//  _____________________________________________

@include min-screen($layout__max-width - $layout-indent__width * 2) {
    .message.global {
        &.cookie {
            .content {
                border-width: 1px 1px 0 1px;
            }
        }
    }
}

//
//  Usps extended
//  _____________________________________________

.usps-extended {

    h3 {
        margin: 0;
        color: $brand__primary__color;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 10px;
    }

    ul {
        @include lib-list-reset-styles();
    }

    li {
        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-size: 28px,
            $_icon-font-margin: 0 10px 0 0,
            $_icon-font-display: flex,
            $_icon-font-color: #ed641c
        );
        position: relative;
        padding-left: $indent__xl;
        margin-bottom: 20px;
        align-items: center;

        &::before {
            position: absolute;
            left: 0;

        }

        &:nth-child(1) {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-clock
            );
        }

        &:nth-child(2) {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-phone
            );
        }

        &:nth-child(3) {
            @include lib-icon-font-symbol(
                $_icon-font-content: $icon-comment
            );
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        //.ie10 &,
        //.ie11 & {
        //    height: 100%;
        //}
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            display: flex;
            justify-content: space-between;

            > .header.links {
                font-size: 0;
                color: $color-white;
                @include lib-list-inline();

                li {
                    &:not(:last-child) {
                        margin: 0 45px 0 0;
                    }
                }

                &.topbar-links > li {
                    &:not(:last-child) {
                        margin: 0 40px 0 0;
                    }
                }

                > li {
                    margin: 0;
                    font-size: $font-size__base;

                    &.welcome,
                    > a {
                        color: $color-white;
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }

                &.usps li {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-checkmark-secondary,
                        $_icon-font-margin: 0 8px 0 0,
                        $_icon-font-display: inline-block,
                        $_icon-font-color: $color-white,
                        $_icon-font-size: 13px
                    );
                }

                > .customer-welcome,
                > .authorization-link {
                    i.icon-user {
                        @include lib-icon-font(
                            $_icon-font-content: $icon-account,
                            $_icon-font-margin: 0 0 0 4px,
                            $_icon-font-display: inline-block,
                            $_icon-font-color: $color-white,
                            $_icon-font-size: 13px,
                            $_icon-font-position: after
                        );

                        &:after {
                            font-style: normal;
                        }
                    }
                }

                > .customer-welcome {
                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: 24px 20px;

            &::before,
            &::after {
                display: none;
            }
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            background-color: $brand__primary__color;
        }

        .header.panel {
            padding-bottom: 7px;
            padding-top: 7px;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector: '.action.switch',
            $_options-selector: 'ul',
            $_dropdown-actions-padding: 0,
            $_dropdown-list-item-padding: 0,
            $_dropdown-toggle-icon-content: $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide: true,
            $_icon-font-size: 10px,
            $_icon-font-margin: 0 0 0 5px,
            $_icon-font-line-height: 22px,
            $_icon-font-color: $color-white,
            $_icon-font-color-hover: $color-white,
            $_icon-font-color-active: $color-white,
            $_dropdown-list-pointer-position: right,
            $_dropdown-list-position-right: 0,
            $_dropdown-list-z-index: $customer-welcome__z-index
        );

        li {
            a {
                @include lib-link(
                    $_link-color: $color-gray20,
                    $_link-text-decoration: none,
                    $_link-color-visited: $color-gray20,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $color-gray20,
                    $_link-text-decoration-hover: none,
                    $_link-color-active: $color-gray20,
                    $_link-text-decoration-active: none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
        }

        .header.links {
            min-width: 156px;

            .authorization-link {
                display: none;
            }
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .logo {
        margin: 0;

        img {
            max-height: 100%;
        }
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .footer-columns {
        display: flex;
    }

    .message.global {
        &.cookie {
            .actions {
                margin-left: $indent__s;
            }
        }
    }

}

.footer-top-wrapper {
    background: $brand__primary__color;
    padding: 60px 0;

    &__bottom {
        display: flex;
        justify-content: space-between;
    }

    h3 {
        color: white;
        text-transform: uppercase;
        padding: 0 0 30px 0;
        margin: 0;
    }

    .inner-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    &__item {
        color: white;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;

        p {
            font-weight: 400;
            margin: 0;
        }


        span {
            font-weight: 600;
        }

        @include lib-icon-font(
            $_icon-font-content: false,
            $_icon-font-color: #fff,
            $_icon-font-display: flex,
            $_icon-font-vertical-align: center,
            $_icon-font-margin: 0 20px 0 0);

        &.clock {
            @include lib-icon-font(
                    $icon-clock,
                $_icon-font-size: 45px,
                $_icon-font-display: flex
            );
        }

        &.delivery {
            @include lib-icon-font(
                    $icon-delivery,
                $_icon-font-size: 45px,
                $_icon-font-display: flex
            );
        }

        &.discount {
            @include lib-icon-font(
                    $icon-discount,
                $_icon-font-size: 45px,
                $_icon-font-display: flex
            );
        }

        &::before {
            //position: absolute;
            //margin-right: 45px;
        }
    }
}

.sidebar {
    ul {
        padding: 0;
    }

    a {
        color: #6d6d6c;
    }

    ul.level1 {
        margin-top: 15px;
    }

    a.level-top {
        font-family: $heading__font-family__base;
        font-weight: 600;
        line-height: 1.1;
        font-size: 1.8rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        color: black;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header {
        border-bottom: none;
    }

    .message.global {
        &.cookie {
            .content {
                flex-direction: column;
            }

            .actions {
                margin-top: $indent__s;
            }
        }
    }

    .footer-top-wrapper {
        padding: 40px 0;

        &__bottom {
            flex-direction: column;
        }

        &__item {
            padding-bottom: 20px;

            &:last-child {
                padding: 0;
            }
        }
    }

    .header {
        &__content-left {
            display: flex;
        }

        &__minicart {
            column-gap: 16px;
        }

        &__mobile-search {
            display: block;
            position: relative;
            margin-top: 15px;
            width: 100%;

            input#search {
                border-radius: 100px;
                padding: 12px 24px;
            }

            .action.search {
                @include lib-button-icon(
                    $_icon-font-content         : $icon-search,
                    $_icon-font-text-hide       : true,
                    $_icon-font-color           : $brand__primary__color,
                    $_icon-font-color-hover     : $brand__primary__color,
                    $_icon-font-color-active    : $brand__primary__color,
                    $_icon-font-size            : 18px,
                    $_icon-font-line-height     : 45px
                );
                @include lib-button-reset();
                position: absolute;
                top: -2px;
                right: 0;
                z-index: 1;
                background: #f5fbfc;
                width: 60px;
                border-radius: 0 40px 40px 0;
            }
        }

        .authorization-link {
            a {
                &:before {
                    font-size: 20px;
                }
            }
        }

        .wishlist-header {
            a {
                &:before {
                    font-size: 18px;
                }

                .counter.qty {
                    top: -8px;
                }
            }
        }

        .minicart-wrapper {
            .action.showcart {
                &:before {
                    font-size: 20px;
                }
            }
        }

        &.content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: $indent__s 15px;
            border-bottom: 1px solid #dcdcdc;

            &:before, &:after {
                display: none;
            }

            .header__search-cart {
                display: flex;
            }

            .nav-toggle {
                position: initial;

                &:before {
                    font-size: 24px;
                }
            }

            .logo {
                margin: 0;

                .logo-mobile {
                    display: block;
                    width: 100px;
                    margin-left: 24px;
                }

                .logo-desktop {
                    display: none;
                }
            }

            .header__search {
                display: none;
            }

            .minicart-wrapper, .block-search {
                margin-top: 0;
            }

            .block-search .label:before {
                margin-top: 4px;
                margin-right: 15px;
            }

            .block-search .control {
                border-top: 0;
            }

            .minicart-wrapper {
                .action.showcart:before {
                    margin: 0 10px 0 0;
                }
            }

            .form.minisearch.active {

                .top-search__container {
                    padding: 0 15px;
                    border-bottom: 1px solid $border-color__base;
                }
            }
        }

        &.panel {
            > .header.links {
                li {
                    @include lib-icon-font(
                        $_icon-font-content: $icon-checkmark-secondary,
                        $_icon-font-size: 13px,
                        $_icon-font-margin: 0 8px 0 0,
                        $_icon-font-color: #fff,
                    );

                    display: none;
                    margin-bottom: 0;

                    &.active {
                        display: block;
                    }
                }
            }

            &.header {
                padding: 11px;
            }
        }
    }

    .wishlist-header {
        margin: 2px 0 0 0;
        padding-right: 5px;
    }
}

.lac-notification {
    display: flex;
    padding: 12px 0;

    .top-container {
        display: flex;
        gap: 12px;

        img {
            display: none;
        }
    }
}

@media only screen and (max-width: 969px) and (min-width: 678px) {
    .header.panel > .header.links:last-child > li {
        &:before {
            display: none;
        }
    }
}

@media (max-width: 1124px) {
    .header.panel {
        justify-content: center;

        & > .header.links {
            &.topbar-links {
                display: none;
            }
        }
    }
}
