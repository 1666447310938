$filter-title-background-color: $color-gray94;
$filter-link: $color-gray-darken4;
$filter-link-hover: darken($filter-link, 30%);
$filter-quantity: $color-gray46;

.trigger-filters {
    @include lib-button();
    @include lib-link-as-button();
    @include lib-css(border-radius, $button__border-radius);
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 10px 20px;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

@keyframes rotate-loader {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.blueprint-loader img {
    -webkit-animation: rotate-loader .7s infinite;
    animation: rotate-loader .7s infinite;
}

.autocomplete-wrapper {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #d8dbe5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    min-height: 3.5rem;
    box-sizing: border-box;
    text-align: left;
    z-index: 1000;
    position: absolute;
    width: 100%;
    display: none;

    &.active {
        display: block;
    }

    .autocomplete {
        .item-count {
            padding: 8px 10px;
            text-align: right;
            font-size: 13px;
            line-height: 13px;
            color: #999;
        }

        ul.item-wrapper {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                &:not(:last-of-type) {
                    border-bottom: 1px solid #d8dbe5;
                }
            }

            .autocomplete-item {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 5px 1rem;
                cursor: pointer;



                &:hover {
                    background-color: #f5f5f5;
                    text-decoration: none;
                }

                .image-wrapper {
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    margin-right: 1rem;
                }

                .meta {
                    display: flex;
                    justify-content: center;
                    overflow: hidden;

                    .title {
                        display: block;
                        margin-bottom: 0;

                        span {
                            color: #333;
                            text-decoration: none;
                        }
                    }
                }

            }
        }

        .categories {
            padding-left: 10px;
        }

        .no-results {
            display: flex;
            justify-content: center;
            padding: 8px 10px;
            flex-direction: column;

            span {
                text-align: center;
                font-size: 1.2rem;
            }

            .suggestions {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-top: 10px;

                .suggestion-text {
                    font-weight: 700;
                    margin-right: 15px;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    justify-content: center;

                    li {
                        margin-right: 15px;
                        display: block;
                        padding: 2px 12px;
                        border-radius: 20px;
                        border: 1px solid #d8dbe5;
                        font-weight: 600;

                        &:hover {
                            cursor: pointer;
                            border-color: #c9cddb;
                        }
                    }
                }
            }
        }
    }
}

.autocomplete-loader {
    position: absolute;
    top: 10px;
    right: 85px;
    width: 25px;
    display: none;

    &.active {
        display: block;
    }
}

.product-list-wrapper.loading {
    opacity: 0.5;
}

.filter {
    &.block {
        margin-bottom: 0;
    }

    &-title {
        strong {
            border: 1px solid $border-color__base;
            border-radius: 3px;
            font-weight: 400;
            left: 0;
            line-height: 16px;
            padding: 7px $indent__s;
            position: absolute;
            text-align: center;
            top: 0;
            z-index: 2;
            display: none;

            &[data-count]:after {
                @include lib-css(color, $color-white);
                background: $color-orange-red1;
                border-radius: 2px;
                content: attr(data-count);
                display: inline-block;
                font-size: 0.8em;
                line-height: 1;
                margin: 0 $indent__xs;
                min-width: 1em;
                padding: 2px;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .block-subtitle {
        border-bottom: $border-width__base solid $border-color__base;
        font-size: $font-size__base;
        font-weight: $font-weight__semibold;
        line-height: 1em;
        padding: $indent__s $indent__s $indent__base 0;
    }

    &-subtitle {
        display: none;
    }

    &-current {
        margin: 0;

        .items {
            padding: $indent__xs 0;
        }

        .item {
            padding-left: 17px;
            position: relative;
            z-index: 1;
        }

        .filter &-subtitle {
            border: none;
            display: block;
            padding-bottom: $indent__s;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -2px;
            position: absolute;
            top: -1px;
        }
    }

    &-actions {
        margin-bottom: $indent__m;
        padding: 0 $indent__s 0 0;
    }

    &-label {
        font-weight: $font-weight__bold;

        &:after {
            content: ': ';
        }
    }

    &-value {
        @include lib-css(color, $filter-quantity);
    }

    &-options {
        display: none;
        margin: 0;

        &-title {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 13px,
                $_icon-font-position: after,
                $_icon-font-display: block
            );
            cursor: pointer;
            font-weight: 400;
            margin: 0;
            overflow: hidden;
            padding: $indent__s $indent__s + 30px 0 0;
            position: relative;
            word-break: break-all;
            z-index: 1;
            font-size: 18px;
            color: $brand__primary__color;

            &:after {
                position: absolute;
                right: 13px;
                top: 12px;
                display: none;
            }

            &:hover {
                @include lib-css(color, $filter-link-hover);
            }

            .active > & {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        &-content {
            margin: 0;
            padding: $indent__s 0;

            &.open {
                .read-more {
                    display: none;
                }

                .item.more:not(.filtered) {
                    display: block;
                }
            }

            &:not(.open) {
                .read-less {
                    display: none;
                }
            }

            .item {
                line-height: 1.5em;
                margin: $indent__s 0;
                font-size: 15px;

                &.more, &.filtered {
                    display: none;
                }
            }

            &.searching {
                .read-more, .read-less {
                    display: none;
                }

                .item.more:not(.filtered) {
                    display: block;
                }
            }

            a.c-checkbox {
                @include lib-css(color, $filter-link);
                margin-left: -5px;
                margin-right: -5px;
                padding-left: 5px;
                padding-right: 7px;

                &:hover {
                    text-decoration: none;
                }
            }

            .label {
                cursor: pointer;
            }

            .count {
                display: none;
            }

            .c-checkbox label {
                padding-left: 24px;
                font-size: 15px;
                line-height: 0;
            }

            .c-checkbox .checkbox:checked ~ .label {
                span {
                    line-height: 17px;
                }

                &::after {
                    font-size: 10px;
                    line-height: 14px;
                }
            }

            .count {
                @include lib-css(color, $filter-quantity);
                font-weight: $font-weight__light;
                padding-left: 5px;
                padding-right: 5px;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }

            .filter-count-label {
                @include abs-visually-hidden();
            }
        }
    }

    &.mobile.filter-state {
        display: none;
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    body {
        &.filter-active {
            .page-header {
                display: none;
            }

            .page-wrapper {
                height: 0;
                margin-top: -999999em;
                visibility: hidden;
            }

            .columns {
                z-index: 999;
            }
        }
    }

    .filters {
        .subcategory-list {
            display: none;
        }
    }

    .filter {
        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item {
                &:last-child {
                    margin-bottom: $indent__xl;
                }
            }

            .filter-title {
                height: 48px;
                left: 0;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 2;

                strong {
                    @include lib-icon-font(
                            $icon-remove,
                        $_icon-font-color: $text__color__muted,
                        $_icon-font-size: 16px,
                        $_icon-font-position: after
                    );
                    @include lib-css(box-shadow, none);
                    background: none;
                    border: 0;
                    color: transparent;
                    left: auto;
                    right: 3px;
                    top: 10px;

                }
            }

            .filter-subtitle {
                @include lib-css(background, $toolbar-background);
                display: block;
                height: 50px;
                left: 0;
                line-height: 32px;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 1;
                display: none;
            }

            .filter-options {
                @include lib-css(background, $color-white);
                bottom: 0;
                display: block;
                left: 0;
                overflow: scroll;
                position: fixed;
                right: 0;
                top: 50px;
                z-index: 10;
            }
        }
    }

    .filter {
        & &-subtitle {
            font-size: 20px;
            font-weight: $font-weight__light;
        }

        &-options-item {
            margin-left: 15px;
        }

        &-options-content {
            padding: 0;
        }
    }

    .filter .filter-current {
        border: solid $border-color__base;
        border-width: 1px 0;
        margin: $indent__xs (-$indent__s) 0;

        .items {
            display: none;
        }

        &-subtitle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: block
            );
            position: relative;
            text-transform: uppercase;
            z-index: 1;

            &:before {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }

        &.active {
            padding-bottom: 30px;

            .block-subtitle {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: before
                );
            }

            .items {
                display: block;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255, 255, 255, 0.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: $indent__base;
        }
    }

    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
    }
}

@media (max-width: $screen__m) {
    html {
        &.filter-active {
            height: 100%;
            overflow-x: hidden;
            width: 100%;

            body {
                height: 100%;
                overflow: hidden;
                position: relative;
                width: 100%;
            }

            .page-wrapper {
                height: 100%;
                overflow: hidden;
                position: relative;
            }

            .page-products .columns {
                z-index: 98;
            }


            .trigger-filters {
                display: block;

                &::after {
                    background: rgba(0, 0, 0, 0.5);
                    content: '';
                    display: block;
                    height: 100%;
                    position: fixed;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: 40;
                }
            }

            .filter-options {
                transform: translateX(0);
            }
        }
    }

    .filter {
        &.active {
            position: relative;
            visibility: visible;
            z-index: 99;

            .filter-options-item:last-child {
                margin-bottom: 40px;
            }

            .filter-options {
                display: block;
            }
        }
    }

    .filter {
        &-title {
            transform: translateX(-100%);
            height: 48px;
            left: 0;
            position: fixed;
            top: 0;
            z-index: 99;
            width: 40vh;
            background-color: white;
            transition: transform 0.2s;
        }

        &-options {
            display: block;
            transform: translateX(-100%);
            bottom: 0;
            left: 0;
            overflow-y: scroll;
            position: fixed;
            top: 48px;
            z-index: 99;
            transition: transform 0.2s;
            padding: 30px 0;
            width: calc(100% - 54px);
            background-color: white;

            .filter-options-item {
                max-width: 80%;
            }

        }

        &-options-item {
            margin-left: 15px;
        }

        &-options-content {
            padding: 0;
        }
    }

    .filter .filter-current {
        .items {
            display: none;
        }

        &-subtitle {
            position: relative;
            text-transform: uppercase;
            z-index: 1;

            &:before {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }

        &.active {

            .items {
                display: flex;
            }

            & + .block-actions {
                display: block;
            }
        }

        & + .block-actions {
            display: none;
        }
    }

    .column.main {
        .filter {
            &-current {
                margin-bottom: 10px;
            }

            &-current.active {
                margin-bottom: 0;
                border-width: 1px 0 0;
            }

            &-actions {
                margin-bottom: 10px;
                border-width: 0 0 1px;
            }
        }
    }

    .filter-no-options {
        .filter-title {
            &:before {
                background: rgba(255, 255, 255, 0.5);
                content: '';
                display: block;
                height: 40px;
                left: -15px;
                margin-top: -60px;
                position: relative;
                width: 100px;
                z-index: 99;
            }
        }

        .filter-content {
            margin-bottom: 12px;
        }
    }

    .catalog-category-view {
        .trigger-filters {
            background-color: #308b8f;
            color: #fff;
            display: block;
            width: 100%;
        }

        .toolbar-amount {
            display: none;
        }
    }

    .page-wrapper {
        left: 0;
        transition: left 0.3s;
    }


}

@include min-screen($screen__m) {
    .filter {
        &.block {
            margin-bottom: $indent__xl;
        }

        &-title {
            display: none;
        }

        &-content {
            .item {
                margin: $indent__s 0;
            }
        }

        &-actions {
            margin-bottom: $indent__l;
        }

        &.active &-options,
        &-options {
            background: transparent;
            clear: both;
            display: block;
            overflow: initial;
            position: static;
        }

        &-subtitle {
            display: none;
            position: static;
        }
    }

    .page-layout-1column {
        .toolbar-products {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .products ~ .toolbar-products {
            position: static;
        }

        &.page-with-filter .column.main {
            padding-top: 45px;
            position: relative;
            z-index: 1;
        }

        .filter {
            &.block {
                border-top: 1px solid $border-color__base;
            }

            &-content {
                margin-top: $indent__s;
            }

            &-subtitle {
                display: none;
            }

            &-options {
                &-item {
                    border: 0;
                    display: inline-block;
                    margin-right: $indent__m;
                    position: relative;

                    &.active {
                        z-index: 2;

                        .filter-options-content {
                            visibility: visible;
                        }

                        &:hover {
                            z-index: 3;
                        }

                        &:after,
                        &:before {
                            @include lib-arrow(up, 8px, $color-black);
                            bottom: -1px;
                            content: '';
                            display: block;
                            left: $indent__xs;
                            position: absolute;
                            z-index: 3;
                        }

                        &:after {
                            @include lib-css(border-bottom-color, $color-white);
                            margin-top: 2px;
                            z-index: 4;
                        }
                    }
                }

                &-title {
                    padding: 0 $indent__base 0 0;

                    &:after {
                        right: 2px;
                        top: 3px;
                        z-index: 3;
                    }
                }

                &-content {
                    $_shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);

                    @include lib-css(background, $color-white);
                    @include lib-css(box-shadow, $_shadow, 1);
                    border: 1px solid $border-color__base;
                    padding: $indent__xs 0;
                    position: absolute;
                    top: 100%;
                    visibility: hidden;
                    width: 180px;
                    z-index: 2;

                    .item {
                        margin: 0;
                        padding: $indent__xs;

                        a {
                            margin-left: 0;
                        }

                        &:hover {
                            background-color: $color-gray91;
                        }
                    }
                }
            }

            &-current {
                display: inline;
                line-height: 35px;

                &-subtitle {
                    @include lib-css(color, $text__color__muted);
                    display: inline;
                    font-size: $font-size__base;
                    font-weight: normal;
                    padding: 0;

                    &:after {
                        content: ':';
                    }
                }

                .item,
                .items {
                    display: inline;
                }

                .item {
                    margin-right: $indent__m;
                    white-space: nowrap;
                }

                .action.remove {
                    line-height: normal;
                }
            }

            &-actions {
                display: inline;
                white-space: nowrap;

                & ~ .filter-options {
                    margin-top: $indent__m;
                }
            }
        }
    }
}
