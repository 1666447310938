.our-people-wrapper {
    display: flex;
    flex-wrap: wrap;

    .section-logo {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 75px;
        margin-bottom: 10px;

        img {
            height: 55px;
            width: auto;
        }

        &.vetplus {
            img {
                height: 40px;
            }
        }

        &.accesia {
            img {
                height: 75px;
            }
        }
    }

    &__employee {
        width: 33%;
        padding-top: 55px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            max-width: 297px;
            max-height: 320px;
        }

        .employee-title {
            color: $color-gray40;
            font-size: 14px;
            font-weight: $font-weight__semibold;
            margin-top: 20px;
        }

        .employee-name {
            font-weight: $font-weight__semibold;
            font-size: 20px;
            color: $color-gray20;
            margin-bottom: 20px;
        }

        .button-wrapper {
            a {
                color: $brand__primary__color;
                font-weight: $font-weight__semibold;
                border: 1px solid $brand__primary__color;
                padding: 10px 18px;
                border-radius: 25px;
                text-decoration: none;

                &:hover {
                    background-color: $brand__primary__color;
                    color: $color-white;
                }

                &:not(:last-child) {
                    margin-right: 3px
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .columns {
        .our-people-wrapper {
            margin: 0 -10px;

            &__employee {
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 0;

                img {
                    max-height: 200px;
                }

                .button-wrapper {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    a {
                        margin-bottom: 5px;
                    }
                }
            }

            .section-logo {
                display: flex;
                justify-content: center;
            }
        }
    }
}

@include max-screen($screen__l) {
    .our-people-wrapper {
        &__employee {
            padding-right: 20px;
            box-sizing: border-box;

            .button-wrapper {
                display: flex;
                align-items: flex-start;
            }
        }
    }
}

@include min-screen($screen__m) {
    .cms-over-ons {
        .page-title-wrapper {
            margin-top: 20px;

            h1 {
                margin-bottom: 20px;
            }
        }

        .about-info {
            width: 50%;

            p {
                line-height: 26px;
            }
        }
    }
}

@include max-screen($screen__xs) {
    .columns {
        .our-people-wrapper {
            &__employee {
                img {
                    max-height: 150px;
                }

                .employee-title {
                    font-size: 13px;
                }

                .employee-name {
                    font-size: 16px;
                }
            }
        }
    }
}
