.contact-index-index {
    .page-title-wrapper,
    .contact-information {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #778499;

        .fading-line {
            height: 1px;
            background: #d5dbe5;
            background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 350, from(#d5dbe5), to(#fff));
            margin: 40px 0 25px 0;
        }

        h3 {
            margin-bottom: 5px;
        }

        h2 {
            color: black;
            width: 35%;
            margin: 0 auto;
        }

        .call-to-btn {
            @include lib-icon-font(
                    $_icon-font-content: $icon-phone,
                    $_icon-font-size: 14px,
                    $_icon-font-color: white,
                    $_icon-font-display: flex);
            width: 10%;
            background: $brand__primary__color;
            color: white;
            padding: 10px 20px;
            border-radius: 6px;
            margin: 20px auto 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;

            &::before {
                padding-right: 5px;
            }
        }
    }

    .field-wrapper {
        display: flex;

        .label-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .contact-form-wrapper {
        max-width: 80%;
        margin: 40px auto 0 auto;

        input[type="text"],
        input[type="password"],
        input[type="url"],
        input[type="tel"],
        input[type="search"],
        input[type="number"],
        input[type="datetime"],
        input[type="email"],
        textarea {
            border-radius: 6px;
            border-color: #d5dbe5;
            margin-bottom: 10px;
        }
    }

    .page-title {
        margin-bottom: 10px;

        .base {
            color: black
        }
    }

    .column:not(.sidebar-main) .form.contact,
    .column:not(.sidebar-additional) .form.contact {
        button {
            padding: 12px 15px;
            font-size: 1.5rem;
        }
    }
}

@include max-screen($screen__m) {
    .contact-index-index {
        .column:not(.sidebar-main) .form.contact,
        .column:not(.sidebar-additional) .form.contact {

            &.contact-form {
                width: 100%;
            }
        }

        .contact-information {

            h2 {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
